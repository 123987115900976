{dpr} = require "DevicePixelRatio"
{TextLayer} = require "TextLayer"

class CardLayer extends Layer
	
	constructor: (@options={}) ->
		@options.hasHeader ?= false
		@options.hasBody ?= false
		@options.hasFooter ?= false
		@options.icon ?= ""
		@options.iconColor ?= "black"
		@options.midX ?= Screen.width/2
		@options.kind ?= "standard"
		@options.text1 ?= "text 1"
		@options.text2 ?= "text 2"
		@options.text3 ?= "text 3"
		@options.text4 ?= "8 min read"
		@options.width ?= dpr 330
		@options.height ?= 104
		@options.link ?= "http://www.richzarick.com"
		@options.media ?= "images/test.jpg"
		@options.backgroundColor ?= "#ffffff"
		
		@options.shadowX = dpr -3
		@options.shadowY = dpr 2
		@options.shadowBlur = dpr 8
		@options.shadowSpread = dpr 2
		@options.shadowColor = "rgba(0,0,0,0.15)"
		@options.name = "#{@options.kind}_card"
		
		
		switch @options.kind 
			when "instagram" 
				@makeInstagramCard()
			when "twitter" 
				@makeTwitterCard()
			when "youtube"
				@makeYoutubeCard()
			when "medium"
				@makeMediumCard()
		super @options
		
		if @options.hasHeader == true
			header = new Layer
				parent: @
				y:0
				height: dpr 32
				width:@.width
				backgroundColor: ""
			hasHeader = false
			headerText = new TextLayer
				parent: header
				height: header.height
				width: header.width - dpr 44
				text: @options.text4
				fontFamily: "Muli"
				color: "#767676"
				fontSize: dpr 11
				lineHeight: 2.5
				paddingLeft: dpr 16
			if @options.kind == "medium"
				headerText.color = "white"
				headerMain = headerText.copy()
				headerMain.props =
					text: @options.text1
					parent: header
					y: dpr 16
					fontSize: dpr 15
					paddingTop: dpr 8
					paddingBottom: dpr 4
					autoSizeHeight: true
					lineHeight:1.27
					color: "black"
				@.height = headerMain.maxY + dpr 32
				
				
		if @options.hasBody == true
			body = new Layer
				y:0
				index:-3
				parent: @
				width:@.width
				backgroundColor: ""
			hasBody = false
			if @options.kind == "youtube" 
				tempURL = @options.link.substr(16)
				body.height = dpr 184
				body.image = "https://img.youtube.com/vi/#{tempURL}/default.jpg"
				
			if @options.kind == "twitter" 
				bodyText = new TextLayer
					parent:body
					autoSizeHeight: true
					width:dpr 330 - 48
					fontSize: dpr 22
					fontFamily: "Muli"
					lineHeight: 1.273
					text: @options.text1
					color: "black"
					paddingLeft: dpr 16
					paddingRight: dpr 16
					paddingTop: dpr 16
				body.height = bodyText.height
            
			if @options.kind == "instagram" 
				body.height = dpr 362
				body.image = @options.media
			
				
		if @options.hasFooter == true
			footer = new Layer
				parent: @
				height: dpr 32
				maxY:@.height
				width:@.width
				backgroundColor: ""
			hasFooter = false
			footerText = new TextLayer
				parent: footer
				height: footer.height
				width: footer.width
				text: @options.text3
				fontFamily: "Muli"
				color: "#767676"
				autoSizeHeight: true
				fontSize: dpr 12
				lineHeight: 2
				paddingLeft: dpr 16
				paddingTop: dpr 6
			if @options.kind == "twitter"
				footer.y = body.maxY
				@.height = footer.maxY
			if @options.kind == "youtube"
				footer.height = dpr 48
				footer.backgroundColor = "#ffffff"
				footer.maxY = @.height
				footerText.y += dpr 16
				footerMain = footerText.copy()
				footerMain.props =
					text: @options.text1
					parent: footer
					y: dpr 2
					x:dpr 16
					padding: 0
					fontSize: dpr 15
					color: "black"
			if @options.kind == "medium"
				footerText.color = "white"
			if @options.kind == "instagram"
				footer.backgroundColor = "white"
		icon = new TextLayer
			parent: @
			color: @options.iconColor
			maxX: dpr 330
			maxY: @.height
			height: dpr 32
			width: dpr 32
			fontSize: dpr 16
			backgroundColor: ""
			lineHeight: 2
			textAlign: "center"
			fontFamily: "FontAwesome"
			text: @options.icon
		if @options.kind == "medium"
			icon.backgroundColor = "#ffffff"
	
	makeInstagramCard: ->
		@options.icon = ""
		@options.iconColor = "black"
		@options.height = dpr 362
		@options.hasBody = true
		@options.hasFooter = true
		
	makeTwitterCard: ->
		@options.hasBody = true
		@options.hasFooter = true
		@options.icon = ""
		@options.iconColor = "#55ACEE"
	
	makeYoutubeCard: ->
		@options.icon = ""
		@options.iconColor = "#D12122"
		@options.height = dpr 184
		@options.hasBody = true
		@options.hasFooter = true
	
	makeMediumCard: ->
		@options.icon = ""
		@options.backgroundColor = "#00ccaa"
		@options.height = dpr 184
		@options.iconColor = "#black"
		@options.hasHeader = true
		@options.hasFooter = true

exports.CardLayer = CardLayer